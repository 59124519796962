export const environment = {
  production: true,
  env: 'prodin',
  host: 'futureskillsprime.in',

  hedwigUrl: 'https://hedwig.futureskillsprime.in',
  saharaUrl: 'https://my.futureskillsprime.in',
  instructorMarketUrl: 'https://instructors.futureskillsprime.in',
  serviceMarketUrl: 'https://instructors.futureskillsprime.in',
  integrationFramework: 'https://integration.futureskillsprime.in',
  amberBaseUrl: 'https://amber.edcastmarketplace.com',
  amberUrl: 'https://amber.edcastmarketplace.com/api',
  bouncerUrl: 'https://account.futureskillsprime.in',
  sphinxUrl: 'https://downloads.futureskillsprime.in',

  welcomePageCampaignKey: 'product-marketplace.welcome-page.campaign',
  welcomePagePromotionKey: 'product-marketplace.welcome-page.promotion',

  aws: {
    key: 'AKIAWLQHGLXHE6ZVEZWE',
    s3Url: 'https://downloads.futureskillsprime.in/proxy/service/jwt',
    cdnBucket: 'leap-prodin-cdn',
    uploadsBucket: 'leap-prodin-marketplace-uploads',
  },

  adyen: {
    clientKey: ''
  },

  okta: {
    issuer: 'https://edcasteu.okta-emea.com/oauth2/aus79vxggeVUGS9tx0i7',
    clientId: '00a-prodin-env',
    prefix: 'prodin'
  }
};
